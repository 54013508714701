@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Familjen Grotesk";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/familjen-grotesk-v8-latin-regular.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Familjen Grotesk";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/familjen-grotesk-v8-latin-600.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

html,
body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.App {
  width: 100%;
  max-width: 950px;
  margin-inline: auto;
  padding: 2rem;
  color: #0e1111;
  font-family: "Familjen Grotesk";
  font-size: 18px;
  line-height: 1.5;

  p {
    max-width: 45rem;
    margin: 0;
  }

  @media (max-width: 750px) {
    font-size: 14px;
  }

  @media (max-width: 550px) {
    padding: 1.5rem;
    font-size: 12px;
  }
}

h1,
h2 {
  font-weight: 600;
  text-transform: uppercase;
}

h1 {
  margin: 0;
}

h2 {
  margin: 2rem 0 0.25rem;
}

.shift-slider {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;

  label {
    font-weight: 600;
    text-transform: uppercase;
  }

  .wrapper {
    position: relative;
    display: inline-block;
    margin: 0 1ch;
    vertical-align: middle;

    .slide,
    .background {
      position: absolute;
      inset: 0;
      display: block;
      width: calc(100% + 1.5ch);
      height: 0.5rem;
      margin: 0.5rem -1ch;
      background: currentColor;
      border-radius: 5px;
      transform-origin: left;
      pointer-events: none;
    }

    .background {
      background: rgba(0 0 0 / 20%);
    }
  }

  input {
    display: block;
    margin: 0 -1ch;
    height: 1.5rem;
    opacity: 0;
  }

  .handle {
    position: absolute;
    top: 0;
    display: grid;
    place-items: center;
    width: 1.5rem;
    height: 1.5rem;
    transform: translateX(-50%);
    background: #0e1111;
    border-radius: 50%;
    color: white;
    font-size: 13px;
    font-weight: 600;
    pointer-events: none;
    z-index: 1;
  }
}

.palette {
  display: flex;
  gap: 0.5rem;

  & + .palette {
    margin-top: 1rem;
  }
}

input[type="color"] {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
}

.swatch {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px rgba(0 0 0 / 15%);
  overflow: hidden;
  cursor: pointer;

  div {
    position: relative;
    width: 100%;
    aspect-ratio: 2 / 1;

    &:after {
      content: "";
      position: absolute;
      inset: 0;
      display: grid;
      place-items: center;
      mask-image: url("../assets/copy.svg");
      -webkit-mask-image: url("../assets/copy.svg");
      mask-position: center;
      -webkit-mask-position: center;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      background-color: currentColor;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.15s ease-out;
    }
  }

  &:hover {
    div:after {
      opacity: 1;
    }
  }

  label {
    display: block;
    padding: 0.25rem 0.5rem;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.1;
    text-transform: uppercase;
    cursor: pointer;
  }

  @media (max-width: 750px) {
    div {
      aspect-ratio: 1;
    }

    label {
      display: none;
    }
  }

  &.primary {
    transition: box-shadow 0.2s ease-in-out;

    div {
      &:after {
        mask-image: url("../assets/eyedropper.svg");
        -webkit-mask-image: url("../assets/eyedropper.svg");
        opacity: 1;
      }
    }

    &:hover {
      box-shadow: 0 2px 5px rgba(0 0 0 / 60%);
    }
  }
}

.copyright {
  display: flex;
  justify-content: space-between;
  padding: 4rem 0 0;
  font-size: 14px;
  text-align: center;

  p {
    margin: 0;
  }

  a {
    color: inherit;
  }
}
